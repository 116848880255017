export default defineNuxtRouteMiddleware(async (to) => {
  const {status} = useAuth();
  const {getAccountProperties, accounts, selectedProperty} = useGoogleAnalytics();

  if (to.path === '/chat') {
    if (status.value === 'authenticated' && !selectedProperty.value && accounts.value !== undefined) {
      return navigateTo('/tool/select');
    }
  }

  if (to.path === '/tool/select') {
    if (status.value === 'authenticated') {
      await getAccountProperties();
    }
  }
});
